exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capacidades-js": () => import("./../../../src/pages/capacidades.js" /* webpackChunkName: "component---src-pages-capacidades-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contactPage.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politicaCookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-soluciones-js": () => import("./../../../src/pages/soluciones.js" /* webpackChunkName: "component---src-pages-soluciones-js" */),
  "component---src-pages-sostenibilidad-js": () => import("./../../../src/pages/sostenibilidad.js" /* webpackChunkName: "component---src-pages-sostenibilidad-js" */),
  "component---src-pages-traficos-js": () => import("./../../../src/pages/traficos.js" /* webpackChunkName: "component---src-pages-traficos-js" */),
  "component---src-pages-valores-js": () => import("./../../../src/pages/valores.js" /* webpackChunkName: "component---src-pages-valores-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

